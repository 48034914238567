import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { RALEWAY_21 } from 'theme/theme'

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`

const Title = styled.div`
  ${RALEWAY_21}

  color: #646770;
  white-space: nowrap;
`

const Rectangle = styled.div`
  width: 50%;
  height: 3px;
  background: #e4af27;
  margin-top: 5px;

  @media (max-width: 767px) {
    margin: 5px auto 0;
  }
`

export default function SuperTitle ({ title }) {
  return (
    <TitleWrapper>
      <Title data-test="title">
        { title }
        <Rectangle />
      </Title>
    </TitleWrapper>
  )
}

SuperTitle.propTypes = {
  title: PropTypes.string
}
