import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'

import SuperTitle from 'components/layouts/signIn/sTitle'
import Meta from 'components/layouts/meta'
import Logo from 'components/layouts/header/logo'
const Loader = dynamic(() => import('components/utils/loaders/pageLoader'), { ssr: false })
const Image = dynamic(() => import('components/utils/image'), { ssr: false })

const LayoutWrapper = styled.div`
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  min-height: 100vh;
  max-width: 600px;
  position: relative;

  @media (max-width: 991px) {
    min-height: 100vh;
    max-width: unset;
  }
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  overflow: auto;
  padding: 50px;
  padding-left: 12%;

  @media (max-width: 991px) {
    align-items: center;
    padding: 40px 20px;
  }
`

const IllustrationBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  padding: 50px;
  max-height: 100%;

  @media (max-width: 991px) {
    padding-top: 75px;
    padding-bottom: 25px;
    justify-content: center;
  }
`

export default function SignInLayout ({ children, sTitle, title, path, image, description, loading, variant }) {
  return (
    <>
      <Meta title={title} path={path} image={image} description={description} />
      <LayoutWrapper>
        { !loading
          ? <LeftBox>
              <Logo isCenter />
              <IllustrationBox>
                { !variant
                  ? <Image src="homepage1" type="svg" className="illustration" alt="Sign In" />
                  : null }
                { variant === 'signUp'
                  ? <Image src="homepage2" type="svg" className="illustration" alt="Sign Up" />
                  : null }
                { variant === 'signUpNext'
                  ? <Image src="sign-up2" type="png" className="illustration" alt="Sign Up" />
                  : null }
                { variant === 'verifyEmail'
                  ? <Image src="verifyEmail" type="png" className="illustration" alt="Verify email" />
                  : null }
              </IllustrationBox>
            </LeftBox>
          : null }
        <ContentBox>
          <div className="w-400 max-w-100">
            { loading
              ? <Loader />
              : <>
                  <SuperTitle title={sTitle} />
                  { children }
                </> }
          </div>
        </ContentBox>
      </LayoutWrapper>
    </>
  )
}

SignInLayout.propTypes = {
  children: PropTypes.any,
  sTitle: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string,
  image: PropTypes.string,
  loading: PropTypes.bool,
  variant: PropTypes.string,
  description: PropTypes.string
}
